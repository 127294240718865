/**
 * Функция обратного вызова, вызывается при успешной авторизации пользователя через телеграм.
 * @param user - Объект описывающий пользователя телеграм.
 */
function onTelegramAuth(user) {
  const event = new CustomEvent("telegram.auth.success", {
    detail: user,
    bubbles: true,
    cancelable: true,
    composed: false,
  });
  window.dispatchEvent(event);
}
